<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <img :src="logo" alt="" />
      <h2 class="brand-text text-primary ml-1">HBase</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Welcome</h2>
        <p class="mb-2">your email has been verified</p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          @click="loginRoute()"
        >
          Back to Home
        </b-button>
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
import userApi from '@api/users';

export default {
  components: {},
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
  data: () => ({
    downImg: require('@/assets/images/pages/not-authorized.svg'),
    logo: require('@/assets/images/logo/qcs_logo.png'),
  }),
  mounted() {
    this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      const data = {
        token: this.$route.query.t,
        email: this.$route.query.email,
      };

      userApi
        .verifyEmail(data)
        .then(() => {
          //
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loginRoute() {
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
